<div class="solidarity-modal">
    <div class="modal-body">
      <h2 class="modal-heading">Great news! We found you.</h2>
      <p>The CPR you entered already exists in your system.You can continue to set a password and login directly</p>
    </div>
    <div class="modal-footer">
      <a
        routerLink="/login"
        class="btn btn-light-blue d-flex justify-content-center align-items-center"
        (click)="ModalClose()">Continue</a
      >
    </div>
  </div>