import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  constructor() {
    this.initializePostHog();
  }

  private initializePostHog() {
    try{
      posthog.init('phc_Io7V4sreA3fhEQ5UPXubx8ag5WV8NOFFzd0pUcIX6wJ', {
        api_host: 'https://eu.i.posthog.com', // Change this if using a self-hosted PostHog instance
        debug: false,
        person_profiles: 'identified_only'
      });
      console.log('PostHog initialized successfully.');
    }catch(error){
      console.error('Error initializing PostHog:', error);
    }
    
  }

  trackEvent(event: string, properties?: Record<string, any>) {
    posthog.capture(event, properties);
  }

  identifyUser(userId: string, properties?: Record<string, any>) {
    posthog.identify(userId, properties);
  }

  reset() {
    posthog.reset();
  }

  getFeatureFlag(flagName: string): boolean {
    return posthog.isFeatureEnabled(flagName);
  }
}
