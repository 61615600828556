import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {
  ForgotPasswordModel,
  ResetPasswordModel,
} from 'src/app/models/forgot-password/forgot-password-model';
import { LoginModel } from 'src/app/models/login/login.model';
import {
  GetOTPModel,
  OTPModel,
  RegisterModel,
  SendOtpModel,
} from 'src/app/models/register/register-model';
import { environment } from 'src/environments/environment';
import { MethodService } from '../../services/shared/method.service';
import { GenericService } from '../shared/generic.service';
import posthog from 'posthog-js';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  body = new URLSearchParams();
  isSave$: Observable<boolean>;
  isSaveSubject: BehaviorSubject<boolean>;
  isSaveOTP$: Observable<boolean>;
  isSaveSubjectOTP: BehaviorSubject<boolean>;
  isSavePassword$: Observable<boolean>;
  isSaveSubjectPassword: BehaviorSubject<boolean>;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  verfyOtpMessage: GetOTPModel = new GetOTPModel();
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'text',
  });
  options = { headers: this.headers };
  constructor(
    private methodService: MethodService,
    private router: Router,
    private http: HttpClient,
    public genericService: GenericService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('token'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSaveSubject = new BehaviorSubject<boolean>(false);
    this.isSave$ = this.isSaveSubject.asObservable();
    this.isSaveSubjectOTP = new BehaviorSubject<boolean>(false);
    this.isSaveOTP$ = this.isSaveSubjectOTP.asObservable();
    this.isSaveSubjectPassword = new BehaviorSubject<boolean>(false);
    this.isSavePassword$ = this.isSaveSubjectPassword.asObservable();
  }
  public get currentUserTokenValue(): any {
    return this.currentUserSubject.value;
  }
  forgotPassword(email: string) {
    return this.methodService.Post('auth/forgotPassword?email=' + email).pipe(
      map((data) => {
        localStorage.setItem('token', JSON.stringify(data.token));
        this.currentUserSubject.next(data.token);
        return data;
      })
    );
  }
  ForgotPasswordOTPCode(forgotPassword: ForgotPasswordModel): Observable<any> {
    this.isSaveSubject.next(true);
    return this.http
      .post<any>(
        `${environment.apiUrl}` +
        `${environment.version}` +
        `/account/forgotpassword`,
        forgotPassword,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        finalize(() => this.isSaveSubject.next(false))
      );
  }
  GetOTPCode(mobileNumber: SendOtpModel): Observable<any> {
    this.isSaveSubject.next(true);
    return this.http
      .post<any>(
        `${environment.apiUrl}` +
        `${environment.version}` +
        `/account/sendtoken`,
        mobileNumber,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        finalize(() => this.isSaveSubject.next(false))
      );
  }
  VerifyOTPCode(otpCode: OTPModel): Observable<any> {
    this.isSaveSubjectOTP.next(true);
    return this.http
      .post<any>(
        `${environment.apiUrl}` +
        `${environment.version}` +
        `/account/verifytoken`,
        otpCode,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        finalize(() => this.isSaveSubjectOTP.next(false))
      );
  }
  Register(register: RegisterModel): Observable<any> {
    this.isSaveSubjectPassword.next(true);
    return this.http
      .post<any>(
        `${environment.apiUrl}` +
        `${environment.version}` +
        `/account/mobilefirststep`,
        register,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        finalize(() => this.isSaveSubjectPassword.next(false))
      );
  }
  ResetPassword(reset: ResetPasswordModel): Observable<any> {
    this.isSaveSubjectPassword.next(true);
    return this.http
      .post<any>(
        `${environment.apiUrl}` +
        `${environment.version}` +
        `/account/resetpassword`,
        reset,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        finalize(() => this.isSaveSubjectPassword.next(false))
      );
  }

  login(loginData: LoginModel) {
    this.isLoadingSubject.next(true);
    this.body.set('username', loginData.username);
    this.body.set('password', loginData.password);
    return this.methodService.LoginPost('/token', this.body.toString()).pipe(
      map((data) => {
        if (data.access_token != null) {
          localStorage.setItem('token', JSON.stringify(data.access_token));
          this.currentUserSubject.next(data.access_token);
          this.genericService.GetProfile().subscribe((z) => {
            localStorage.setItem('user', JSON.stringify(z));
          });
        }
        return data;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.clearStorage();
    posthog.reset();
    this.router.navigate(['/login']);
  }
  clearStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.currentUserSubject.next(null);
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
