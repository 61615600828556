import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from "@angular/router";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from "@sentry/angular-ivy";
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthPopupComponent } from './components/auth/auth-popup/auth-popup.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { MethodService } from './services/shared/method.service';
import { UserService } from './services/user/user-service';
import { NgPagination } from './shared/paginator/ng-pagination/ng-pagination.component';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { SortIconComponent } from './shared/sort-icon/sort-icon.component';
import { RecaptchaStatusService } from './recaptcha-status.service';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { EmailVerificationComponent } from './components/auth/email-verification/email-verification.component';
import { EmailVerificationRoutingModule } from './components/auth/email-verification/email-verification-routing.module';
import { JwtModule, JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatFormFieldModule } from '@angular/material/form-field';
import { environment } from '../environments/environment';
import { SidebarService } from './services/sidebar/sidebar.service';
import { PosthogService } from './services/posthog.service';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    HttpClientModule,
    NgbDropdownModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    }),
    EmailVerificationRoutingModule
  ],
  declarations: [
    AppComponent,
    PaginatorComponent,
    SortIconComponent,
    NgPagination,
    AuthPopupComponent,
    EmailVerificationComponent,
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    RecaptchaStatusService,
    MethodService,
    UserService,
    DatePipe,
    SidebarService,
    PosthogService
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

}
