import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SumsubRequest } from 'src/app/models/sumsub/sumsub.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MethodService {
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  });
  public headersLogin = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  });
  options = { headers: this.headers, body: [] };
  optionsLogin = { headers: this.headersLogin };
  constructor(private http: HttpClient) { }
  Get(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}` + `${environment.version}` + url,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  PostURL(url: string, model?: any): Observable<any> {
    return this.http
      .post<any>(
        url,
        model,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  Post(url: string, model?: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}` + `${environment.version}` + url,
        model,
        this.options
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  PostHeader(url: string, body: any, headers?: { [key: string]: string }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(headers || {}),
    };
    return this.http.post(`${environment.apiUrl}${url}`, body, httpOptions);
  }

  LoginPostTemp(url: string, username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password);
  
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.version}${url}`,
        body.toString(), // Convert to URL-encoded string
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  LoginPost(url: string, model?: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}` + `${environment.version}` + url,
        model,
        this.optionsLogin
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
  ClaimPost(url: string, model?: any): Observable<any> {
    return this.http
      .post<any>(`${environment.tokenUrl}` + url, model, this.options)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
  FormData(url: string, form: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}` + `${environment.version}` + url,
        form,
        {
          reportProgress: true,
        }
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }


  GetStrapi(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.strapiUrl}` + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // GetAx(url: string, model?: any): Observable<any> {
  //   return Axios.get(url).pipe(
  //     map((response) => {
  //       return response;
  //     })
  //   );
  // }
  PostAx(url: string, model?: any): Observable<any> {
    return Axios.post(`${environment.fileLookUpUrl}` + url, model).pipe(
      map((response) => {
        return response;
      })
    );
  }
  // PostQuoteAx(url: string, model?: any): Observable<any> {
  //   return Axios.post(`${environment.apiUrl}` + url, model).pipe(
  //     map((response) => {
  //       return response;
  //     })
  //   );
  // }

  GetAccessToken(body: SumsubRequest): Observable<any> {
    this.options.body = [body];
    return this.http
      .get<any>(environment.sumSubTokenApi, this.options)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
