import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaStatusService {
  private checkboxStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  setCheckboxStatus(status: boolean) {
    this.checkboxStatus.next(status);
  }

  getCheckboxStatus() {
    return this.checkboxStatus.asObservable();
  }
}
