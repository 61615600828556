import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private route: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
       
        switch (err.status) {
          case 401: {
            window.location.href = '/unathorized';
            break;
          }
          case 302: {
            this.authenticationService.clearStorage();
            break;
          }
          default: {
            break;
          }
        }
        if (err.error != null && err.error != '') {
          if (err.error.Message != undefined && err.error.Message != '')
            return throwError(err.error.Message);
          else return throwError(err.error);
        } else {
          return throwError(err.message);
        }
      })
    );
  }
}
