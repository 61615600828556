import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { PosthogService } from './services/posthog.service';
import posthog from 'posthog-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'solidarity';
  currentUser: any;
  themeLayout = true;

  constructor(
    public _router: Router,
    private authenticationService: AuthenticationService,
    private posthogService: PosthogService
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
  }

  ngOnInit() {
    //posthog.debug();
     // Subscribe to route navigation events
     this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Track a page view for the current route
        this.posthogService.trackEvent('Page Viewed', { 
          path: event.urlAfterRedirects 
        });
      }
    });
  }
}
