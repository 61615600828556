import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SearchFilter } from '../../shared/models/filter.model';
//import { TableService } from '../shared/table.service';
//import { UserModelDto } from 'src/app/models/user/user-modeldto';
import { Router } from '@angular/router';
import { ProfileDto } from 'src/app/models/user/profile.model';
import { MethodService } from '../shared/method.service';
@Injectable({
  providedIn: 'root',
})
export class UserService
 // extends TableService<UserModelDto>
  implements OnDestroy
{
  public disable: boolean = false;
  private searchFilter: SearchFilter;
  private total: number;
  isSave$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(HttpClient) http,
    private method: MethodService,
    private router: Router
  ) {
   // super(http);
    this.searchFilter = new SearchFilter();
  }

  DeleteUser(code: string) {
    return this.method.Get('/user/deleteuser?code=' + code).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetProfileData() {
    return this.method.Get('/profile').pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetPartyCodeFromCPR(cpr: string) {
    var data = { cpr: cpr };
    return this.method.Post('/customer/checkparty', data).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  createParty(profileDto: ProfileDto, headers?: { [key: string]: string }) {
    this.isSave$.next(true);
    return this.method
      .PostHeader('v1.0/customer/new', profileDto, headers)
      .pipe(
        map((data) => {
          return data;
        }),
        finalize(() => this.isSave$.next(false))
      );
  }

  LookUpFiles(cpr: string) {
    var data = { item_id: cpr };
    return this.method.PostAx('lookup', data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  UpdateProfileData(profileDto: ProfileDto) {
    this.isSave$.next(true);
    return this.method.Post('/profile/update', profileDto).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  confirmPassword(Password: string) {
    var data = { Password: Password };
    this.isSave$.next(true);
    return this.method.Post('/account/confirmpassword', data).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  sendOtp(mobileNumber: string) {
    var data = { mobileNumber: mobileNumber };
    this.isSave$.next(true);
    return this.method.Post('/account/sendToken', data).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  updatePhone(Password: string, Phone: string, Pin: string, PinId: string) {
    var data = { Password: Password, Phone: Phone, Pin: Pin, PinId: PinId };
    this.isSave$.next(true);
    return this.method.Post('/account/updatephone', data).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  updateEmail(Password: string, Email: string) {
    var data = { Password: Password, Email: Email };
    this.isSave$.next(true);
    return this.method.Post('/account/updateemail', data).pipe(
      map((data) => {
        return data;
      }),
      finalize(() => this.isSave$.next(false))
    );
  }

  ngOnDestroy() {
   // this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
