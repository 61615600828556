import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageSizes, PaginatorState } from '../models/paginators-model';
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() paginator: PaginatorState;
  @Input() isLoading;
  @Output() paginate: EventEmitter<PaginatorState> = new EventEmitter();
  pageSizes: number[] = PageSizes;
  constructor() {}

  ngOnInit(): void {
    this.paginator.pageStart =
      this.paginator.page == 1
        ? this.paginator.page
        : (this.paginator.page - 1) * this.paginator.pageSize + 1;
    this.paginator.pageEnd =
      this.paginator.total < this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page == 1
        ? this.paginator.pageSize
        : this.paginator.total < this.paginator.page * this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page * this.paginator.pageSize;
  }

  pageChange(num: number) {
    this.paginator.page = num;
    this.paginate.emit(this.paginator);
    this.paginator.pageStart =
      this.paginator.page == 1
        ? this.paginator.page
        : (this.paginator.page - 1) * this.paginator.pageSize + 1;
    this.paginator.pageEnd =
      this.paginator.total < this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page == 1
        ? this.paginator.pageSize
        : this.paginator.total < this.paginator.page * this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page * this.paginator.pageSize;
  }

  sizeChange() {
    this.paginator.pageSize = +this.paginator.pageSize;
    this.paginator.page = 1;
    this.paginate.emit(this.paginator);
    this.paginator.pageStart =
      this.paginator.page == 1
        ? this.paginator.page
        : (this.paginator.page - 1) * this.paginator.pageSize + 1;
    this.paginator.pageEnd =
      this.paginator.total < this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page == 1
        ? this.paginator.pageSize
        : this.paginator.total < this.paginator.page * this.paginator.pageSize
        ? this.paginator.total
        : this.paginator.page * this.paginator.pageSize;
  }
}
