import { FormGroup } from '@angular/forms';

export interface IFilterView {
  filterGroup: FormGroup;
  ngOnInit(): void;
  filterForm(): void;
  filter(): void;
}
export class SearchFilter {
  public displayLength: number;
  public displayStart: number;
  public sortCol: number = 1;
  public sortOrder: string;
  public search: string;
  public filterStatus: string;
  public filterType: string;
}
