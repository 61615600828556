<div class="container" *ngIf="isVerified== true">
    <div class="center">
      <div class="solidarity-modaldoc success-modal">
        <div class="modal-body">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-row">
              <div class="icon-check">
                <i class="bi bi-check-circle-fill"></i>
              </div>
              <h2 class="modal-heading">Email Verified!</h2>
            </div>
  
          </div>
          <div class="text-wrap">
            <p class="mb-5">
              Your email has been successfully verified.
            </p>
            <!-- <p class="mb-4">View & download your insurance policy documents:</p> -->
            <ul class="list-unstyled">
             
            
            </ul>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <p>
            <!-- For any inquiries you may contact our chatbot via
            <a href="https://wa.me/97317130000">Whatsapp</a> or call us at
            <a href="tel:+97317130000">17130000</a> or go back to  -->
            <a href="/">Back to Home</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div>
    <ng-container *ngIf="isLoading$ | async">
      <span
        class="indicator-progress"
        [style.display]="'block'"
      >
        Please wait...
        <span
          class="spinner-border spinner-border-sm align-middle ms-2"
        ></span>
      </span>
    </ng-container>
  </div>

  
  <div class="container" *ngIf="isVerified == false">
    <div class="center">
      <div class="solidarity-modaldoc success-modal">
        <div class="modal-body">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-row">
              <div class="icon-check" >
                <i class="bi bi-exclamation-circle-fill" style="color: #b70000;"></i>
              </div>
              <h2 class="modal-heading">Oops..!</h2>
            </div>
  
          </div>
          <div class="text-wrap">
            <p class="mb-5"> {{errorMessage}} </p>
          </div>
          <div class="modal-footer justify-content-center">
            <p>
              <!-- For any inquiries you may contact our chatbot via
              <a href="https://wa.me/97317130000">Whatsapp</a> or call us at
              <a href="tel:+97317130000">17130000</a> or go back to  -->
              <a href="/">Back to Home</a>
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <p>
            For any inquiries you may contact our chatbot via
            <a href="https://wa.me/97317130000">Whatsapp</a> or call us at
            <a href="tel:+97317130000">17130000</a> 
            <!-- <a href="/">Home</a> -->
            <br>
            <button type="submit" class="btn btn-md btn-primary mt-lg-5 mt-md-5 mt-3 mb-lg-4 mb-md-4 mb-3" style="background-color: #00a9e7; border-color: #00a9e7;" [routerLink]="['/']">
              <ng-container>
                <span class="indicator-label"> Back to Home </span>
              </ng-container>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>