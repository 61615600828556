import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-popup',
  templateUrl: './auth-popup.component.html',
  styleUrls: ['./auth-popup.component.css']
})
export class AuthPopupComponent implements OnInit {

  constructor( private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  ModalClose()
  {
    this.activeModal.close();
  }
}
