import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./components/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./components/auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./components/auth/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('../app/components/shared/shared.module').then(
        (m) => m.SharedModule
      ),
  },
  {
    path: 'confirmemail',
    loadChildren: () =>
      import('./components/auth/email-verification/email-verification-routing.module').then(
        (m) => m.EmailVerificationRoutingModule
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
