import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor() { }
  private actionSource = new Subject<string>();
  action$ = this.actionSource.asObservable();

  triggerAction(action: string) {
    this.actionSource.next(action);
  }
}
