import { Injectable } from '@angular/core';
import { AxiosObservable } from 'axios-observable';
import { BehaviorSubject, map } from 'rxjs';
import { FileUploadModel } from 'src/app/models/files/fileUpload-model';
import { RequestTokenModel } from 'src/app/models/files/requestToken-model';
import { HomeProceedPayment } from 'src/app/models/home-insurance/home-insurance.model';
import {
  PlansBenefitDetail,
  PlansDetail,
} from 'src/app/models/motor-car-quote/motor-car-quote.model';
import { PromoCodeModel } from 'src/app/models/personal-accident/promo-code.model';
import { environment } from 'src/environments/environment';
import { MethodService } from '../shared/method.service';


@Injectable({
  providedIn: 'root',
})
export class GenericService {
  loginUserProfileEmitter$ = new BehaviorSubject<any>([]);
  constructor(private methodService: MethodService) { }
  async DownloadFile(url: string, name: string, exten: string) {
    url = environment.strapiFileUrl + url;
    const a = document.createElement('a');
    a.href = await URL.createObjectURL(
      await fetch(url).then((res) => res.blob())
    );
    a.download = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  async DownloadFiles(url: string, name: string) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.setAttribute('target', '_blank'); // Add this line
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  GetPlans(type) {

    return this.methodService
      .GetStrapi('plans?populate=deep')
      .pipe(
        map((data) => {

          var list: PlansDetail[] = [];
          var plan = data.data.find(
            (z) => z.attributes.identifier.toLowerCase() == type.toLowerCase()
          );
          if (plan) {
            var locale = plan.attributes.locale;
            var lang = localStorage.getItem('lang');
            for (var i = 0; i < plan.attributes.plan_details.length; i++) {
              var item = plan.attributes.plan_details[i];
              list.push({
                benefits: item['benefits_' + lang],
                benefits_desc: item['benefits_desc_' + lang],
                plat: item['plat_' + lang],
                gold: item['gold_' + lang],
                third_party: item['third_party_' + lang],
                flexi: item['flexi_' + lang],
              });
            }
          }
          return list;
        })
      );
  }
  GetPlansBenefits(type) {
    return this.methodService
      .GetStrapi('products?populate=deep')
      .pipe(
        map((data) => {
          var planBenefit: PlansBenefitDetail = new PlansBenefitDetail();
          var findtype = data.data.find(
            (z) => z.attributes.name.trim().toLowerCase() == type.toLowerCase()
          );
          //var locale = findtype.attributes.locale;
          var lang = localStorage.getItem('lang');
          planBenefit.benefits_desc = findtype.attributes.benefits_description;
          planBenefit.benefits_descAr = findtype.attributes.benefits_description_ar;
          planBenefit.description = findtype.attributes.description;
          planBenefit.descriptionAr = findtype.attributes.description_ar;

          for (var i = 0; i < findtype.attributes.benefits.length; i++) {
            var item = findtype.attributes.benefits[i];
            planBenefit.benefits.push({
              feature: item['feature_' + lang],
            });
          }
          for (var i = 0; i < findtype.attributes.exclusions.length; i++) {
            var item = findtype.attributes.exclusions[i];
            planBenefit.exclusions.push({
              feature: item['feature_' + lang],
            });
          }
          for (var i = 0; i < findtype.attributes.documents.length; i++) {
            var item = findtype.attributes.documents[i];
            planBenefit.docs.push({
              title: item['title_' + lang],
              doc: item.document.data.attributes,
            });
          }
          return planBenefit;
        })
      );
  }
  GetUserInfo() {

    var data = localStorage.getItem('user');
    if (data != null && data != '') {
      return JSON.parse(data);
    } else {
      this.GetProfile().subscribe((z) => {
        this.loginUserProfileEmitter$.next(z);
        localStorage.setItem('user', JSON.stringify(z));
        // console.log(z);
        return z;
      });
    }
  }
  GetProfile() {

    return this.methodService.Get('/profile').pipe(
      map((data) => {
 
        return data;
      })
    );
  }
  GetDateFormat(date) {
    // console.log(date);
    if (date != null && date != '') {
      var d = new Date(date);
      // console.log(((d.getTime() - d.getMilliseconds()) / 1000).toString());
      return ((d.getTime() - d.getMilliseconds()) / 1000).toString();
    } else return '';
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }  
  getDateDifference(startDate: Date, endDate: Date): { years: number, months: number, days: number } {
    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();
    let days = endDate.getDate() - startDate.getDate();
  
    if (days < 0) {
      months--;
      days += new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
    }
  
    if (months < 0) {
      years--;
      months += 12;
    }
  
    return { years, months, days };
  }
  getDaysBetweenDates(startDate: string, endDate: string): number {
    // Parse the date strings into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    // Calculate the difference in time (milliseconds)
    const differenceInTime = end.getTime() - start.getTime();
  
    // Convert the time difference to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  
    return differenceInDays;
  }


  ShowStep(showClass: string, hideClass: string) {
    if (showClass == 'claim-step4' || showClass == 'loggedIn-claim-step3') {
      var elBtn = document.getElementsByClassName('stepone')[0];
      elBtn.classList.remove('is-active');
      elBtn.classList.add('is-complete');
      var elBtn = document.getElementsByClassName('steptwo')[0];
      elBtn.classList.add('is-active');
      var elBtn = document.getElementsByClassName(showClass)[0];
      elBtn.classList.add('display-inherit');
      var elBtn = document.getElementsByClassName(hideClass)[0];
      elBtn.classList.remove('display-inherit');
    } else if (
      showClass == 'claim-step5' ||
      showClass == 'loggedIn-claim-step4'
    ) {
      var elBtn = document.getElementsByClassName('steptwo')[0];
      elBtn.classList.remove('is-active');
      elBtn.classList.add('is-complete');
      var elBtn = document.getElementsByClassName('stepthree')[0];
      elBtn.classList.add('is-active');

      var elBtn = document.getElementsByClassName(showClass)[0];
      elBtn.classList.add('display-inherit');
      var elBtn = document.getElementsByClassName(hideClass)[0];
      elBtn.classList.remove('display-inherit');
    } else if (
      showClass == 'claim-step6' ||
      showClass == 'loggedIn-claim-step5'
    ) {
      var elBtn = document.getElementsByClassName('stepthree')[0];
      elBtn.classList.remove('is-active');
      elBtn.classList.add('is-complete');
      var elBtn = document.getElementsByClassName('stepfour')[0];
      elBtn.classList.add('is-active');

      var elBtn = document.getElementsByClassName(showClass)[0];
      elBtn.classList.add('display-inherit');
      var elBtn = document.getElementsByClassName(hideClass)[0];
      elBtn.classList.remove('display-inherit');
    } else {
      var elBtn = document.getElementsByClassName(showClass)[0];
      elBtn.classList.add('display-inherit');
      var elBtn = document.getElementsByClassName(hideClass)[0];
      elBtn.classList.remove('display-inherit');
    }
  }
  formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['KB', 'MB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ShowFirstStep(showClass: string, hideClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
    elBtn.classList.add('display-none');
    this.AddActiveComplete('stepone', 'steptwo');
  }
  ShowZeroStep(showClass: string, hideClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
    elBtn.classList.add('display-none');
    this.AddActiveComplete('stepzero', 'stepone');
  }
  DisplayStep(showClass: string, hideClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
  }
  AddActiveComplete(hideStep: string, showStep: string) {
    var elBtn = document.getElementsByClassName(hideStep)[0];
    elBtn.classList.remove('is-active');
    elBtn.classList.add('is-complete');
    var elBtn = document.getElementsByClassName(showStep)[0];
    elBtn.classList.add('is-active');
  }
  ShowHideStep(
    showClass: string,
    hideClass: string,
    hideStep: string,
    showStep: string
  ) {
    this.DisplayStep(showClass, hideClass);
    this.AddActiveComplete(hideStep, showStep);
  }
  BackFirstStep(hideClass: string, showClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
    this.BackActiveComplete('stepone', 'steptwo');
  }
  BackZeroStep(hideClass: string, showClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
    this.BackActiveComplete('stepzero', 'stepone');
  }
  BackShowHideStep(
    showClass: string,
    hideClass: string,
    hideStep: string,
    showStep: string
  ) {
    console.log("Showing class: " + showClass + " - " + "Hiding class: " + hideClass);
    console.log("Showing step: " + showStep + " - " + "Hiding step: " + hideStep);

    
    this.BackDisplayStep(showClass, hideClass);
    this.BackActiveComplete(hideStep, showStep);
  }
  BackDisplayStep(hideClass: string, showClass: string) {
    var elBtn = document.getElementsByClassName(showClass)[0];
    elBtn.classList.add('display-inherit');
    var elBtn = document.getElementsByClassName(hideClass)[0];
    elBtn.classList.remove('display-inherit');
  }

  BackActiveComplete(showStep: string, hideStep: string) {
    var elBtn = document.getElementsByClassName(showStep)[0];
    elBtn.classList.add('is-active');
    elBtn.classList.remove('is-complete');
    var elBtn = document.getElementsByClassName(hideStep)[0];
    elBtn.classList.remove('is-active');
  }
  ProceedPayment(homeproceedPayment: HomeProceedPayment) {
    return this.methodService.Post('/policy/pay', homeproceedPayment).pipe(
      map((data) => {
        return data;
      })
    );
  }
  GetPreBindCountriesData() {
    return this.methodService.Post('/constants/countries').pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetPreBindNationalitiesData() {
    return this.methodService.Post('/constants/nationalities').pipe(
      map((data) => {
        return data;
      })
    );
  }

  TokenGet(requestToken: RequestTokenModel): AxiosObservable<any> {
    return this.methodService.PostAx('tokens/request', requestToken).pipe(
      map((data) => {
        return data;
      })
    );
  }

  FileUpload(fileModel: FileUploadModel) {
    return this.methodService.PostAx('files/upload', fileModel).pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetNotifications() {
    return this.methodService.Get('/profile/notifications').pipe(
      map((data) => {
        return data;
      })
    );
  }
  GetBanks() {
    return this.methodService.Post('/constants/banks').pipe(
      map((data) => {
        return data;
      })
    );
  }
  GetMotorQuote() {
    return this.methodService.Post('/constants/cars').pipe(
      map((data) => {
        return data;
      })
    );
  }

  GetGarageList() {
    return this.methodService.Post('/constants/garages').pipe(
      map((data) => {
        return data;
      })
    );
  }

  ApplyPromoCode(promoCodeModel: PromoCodeModel) {
    return this.methodService.Post('/policy/applypromo', promoCodeModel).pipe(
      map((data) => {
        return data;
      })
    );
  }
  GetCover(entry: any, coverproperty: any[]) {
    return {
      coverCode: entry.coverCode,
      coverName: entry.coverName,
      coverProperty:
        entry.coverProperty == null || entry.coverProperty == undefined
          ? ''
          : entry.coverProperty,
    };
  }
}
