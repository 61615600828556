import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {HttpClientModule} from '@angular/common/http';
import { Observable, first } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  loading = false;
  isLoading$: Observable<boolean>;
  isVerified: boolean = true;
  errorMessage = "";
  successMessage: any;


  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { 
    this.isLoading$ = this.authenticationService.isLoading$;
  }

  ngOnInit(): void {
    // console.log(this.decrypt("emailVerificationEncryption", new URLSearchParams(window.location.search).get('data')));
    
    // this.getParamsAndDecrypt();
    // this.callVerifyEmail();
    this.getParams();
  }


  getParams() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("Url Param: ", urlParams);
    const paramValue = urlParams.get('data').replace(/\s/g, '+');
    console.log("Parama VaL: ", paramValue);
    

    // URL Parameter exists
    if (paramValue) {
      const decodedParam = decodeURIComponent(paramValue);
      console.log("Decoded: ", decodedParam);
      this.callVerifyEmail(decodedParam);
    }

    
  }

  goToHome() {
    window.location.href = "/";
  }

  callVerifyEmail(cipher: string) {
    const currentUserToken = this.authenticationService.currentUserTokenValue;
      const apiUrl = `${environment.apiUrl}` +
        `${environment.version}` + '/account/verifyemail'; // replace with your API endpoint URL
      const requestBody = {
        "cipher": cipher,
      };
      // const headers = {
      //   // 'Content-Type': 'application/json',
      //   Authorization: 'Bearer ' + currentUserToken
      // };

      this.http.post(apiUrl, requestBody, { observe: 'response' })
        .toPromise()
        .then(response => {
          console.log(response);
          console.log(response.status);
          
          
          if (response.status == 200) {
          this.isVerified = true
          this.loading = false;
          this.successMessage = response;
          }
          else {
          this.isVerified = false
          this.loading = false;
          }
        })
        .catch(error => {
          console.log(error);
          // console.log(error.status);
          this.errorMessage = error;

          this.loading = false;
          this.isVerified = false;
        });
    }



}
