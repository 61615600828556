import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import {
  SumsubRequest
} from 'src/app/models/sumsub/sumsub.model';
import { environment } from 'src/environments/environment';
import { MethodService } from '../shared/method.service';
@Injectable({
  providedIn: 'root',
})

export class SumSubService {
  isAccessTokenEmitter$ = new BehaviorSubject<boolean>(false);
  constructor(private methodService: MethodService) { }

  GetSumSubToken(body: SumsubRequest) {
    return this.methodService.PostURL(`${environment.apiUrl}` + `${environment.version}` + '/account/sumsubtoken', body)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
