import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailVerificationComponent } from './email-verification.component';


// const routes: Routes = [
//   {
//     path: '',
//     component: EmailVerificationComponent,
//     children: Routing
//   },
// ];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule],
// })
// export class EmailVerificationRoutingModule {}

@NgModule({
    imports: [
      RouterModule.forChild([
        {
          path: '',
          component: EmailVerificationComponent,
        },
      ]),
    ],
    exports: [RouterModule],
  })
  export class EmailVerificationRoutingModule {}