export const environment = {
  production: true,
  apiUrl: 'https://api.solidarity.bh/',
  // apiUrl: 'https://api.solidarity.bh/',
  tokenUrl: 'https://files-api.solidarity.bh/',
  filesUrl: 'https://files-api.solidarity.bh',
  strapiUrl: 'https://content.solidarity.bh/api/',
  strapiFileUrl: 'https://content.solidarity.bh',
  strapiImageUrl: 'https://content.solidarity.bh',
  defaultImageUrl: './assets/media/avatars/no-image.png',
  fileLookUpUrl: 'https://files-api.solidarity.bh/',
  // apiDevUrl: 'https://api.staging.solidarity.bh/',
  version: 'v1.0',
  auth: '',
  requestTokenSecret: 'hello123',
  source: 'online.portal',
  productCode: '0101',
  promoChannel: 'MOTOR2021',
  sumSubTokenApi: 'https://api.solidarity.bh/v1.0/account/sumsubtoken',
  sumSubAuth: 'LgAAAB_@_LCAAAAAAAAApzqDQyKfHRNLY1NbENyM80VFVRtrQ0NzYztLKwsDQ2MHIwNo6Njo830DY00tXVBgAGvJ4yLgAAAA_!__!_',
  tempAuthUserName: 'accountsdashboard',
  tempAuthPassword: 'Acc@dash123',
  firebaseConfig: {
    apiKey: "AIzaSyBbJzeSA-Pd6uIbBWMZDkBsOs4JU8yvaFw",
    authDomain: "essential-haiku-254805.firebaseapp.com",
    databaseURL: "https://essential-haiku-254805.firebaseio.com",
    projectId: "essential-haiku-254805",
    storageBucket: "essential-haiku-254805.appspot.com",
    messagingSenderId: "824596323368",
    appId: "1:824596323368:web:2972d69e55e0c37a72291d",
    measurementId: "G-DF8PV3T1SE"
  },
    NewWeb: "https://app.solidarity.bh",
    reactURL: "https://app.solidarity.bh"
};
