export class MotorCarQuote {
  FromDate: number = 1656009000;
  ToDate: number = 1657009000;
  SumInsured: string = '';
  ModelYear: number = 0;
  ModelCode: number = 0;
  MakeCode: number = 0;
  ChasissNumber: string = '';
  PartyCode: string = 'Underage';
  EngineNumber: boolean = null;
  NCDFactor: number = 0;
  Specification: string = 'NORMAL';
  Seats: number = 0;
  PlateCategory: string = 'PVT';
  EngineSize: number = 0;
  VehicleCategory: string = '';
  Age: number = 1;
  TypeCode: string = 'P';
  RegistrationNumber: number = null;
  Color: string = null;
  Cylinder: string = null;
  LenderCode: string = null;
  BodyTypeCode: string = '';
  ModeOfBusiness: string = '';
  PlanName: string = 'TPL';
  IsBrandNew: string = 'false';
  IsNCDApplicable: string = 'false';
  IsModified: string = 'false';
  IsMortgaged: string = 'false';
  IsTPorBreakInsurance: string = 'false';
  IsCoverNotePresent: string = 'false';
  IsDeletedRisk: string = 'false';
  IsExported: string = 'false';
  IsCurrentlyInsured: string = 'false';
  Channel: string = 'mobile.solidarity';
  Covers: any[] = [];
}

export class PlansDetail {
  // benefits_en:string= "";
  // benefits_ar:string= "";
  // benefits_desc_en:string= "";
  // benefits_desc_ar:string= "";
  // third_party_ar:string= "";
  // flexi_en:string= "";
  // flexi_ar:string= "";
  // third_party_en:string= "";

  // gold_en:string= "";
  // gold_ar:string= "";
  // plat_en:string= "";
  // plat_ar:string= "";
  benefits: string = '';
  benefits_desc: string = '';
  plat: string = '';
  gold: string = '';
  flexi: string = '';
  third_party: string = '';
}
export class BenefitsExclusions {
  feature: string = '';
}
export class Exclusions {
  feature: string = '';
}

export class BenefitDocument {
  title: string = '';
  doc: Document;
}
export class Document {
  name: string = '';
  url: string = '';
  size: string = '';
  mime: string = '';
  ext: string = '';
}
export class PlansBenefitDetail {
  description: string = '';
  descriptionAr: string = '';
  benefits_desc: string = '';
  benefits_descAr: string = '';
  benefits: BenefitsExclusions[] = [];
  exclusions: BenefitsExclusions[] = [];
  docs: BenefitDocument[] = [];
}
export enum BenefitType {
  motor = 'Motor Insurance',
  travel = 'Travel',
  domesticHelper = 'Domestic Helper',
  medicalInsurance = 'Medical Insurance',
  personalAccident = 'Personal Accident',
}
export enum PlanType {
  motor = 'Motor',
  travel = 'Travel',
  domesticHelper = 'Domestic Helper',
  medicalInsurance = 'Medical',
  personalAccident = 'Personal Accident',
}
