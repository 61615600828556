import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { SumSubService } from '../services/sumsub/sumsub.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    public sumSubService: SumSubService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUserToken = this.authenticationService.currentUserTokenValue;
    const isLoggedIn = currentUserToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + currentUserToken,
        },
      });
    } else if (this.sumSubService.isAccessTokenEmitter$.getValue()) {
      request = request.clone({
        setHeaders: {
          auth: environment.sumSubAuth,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {},
      });
    }

    return next.handle(request);
  }
}
