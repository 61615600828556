export class RegisterModel {
  cpr: string = '';
  email: string = '';
  phoneNumber: string = '';
  password: string = '';
  confirmPassword: string = '';
  userName: string = '';
  firstName: string = 'PIE';
  middleName: string = 'Payments';
  lastName: string = 'International';
  gender: string = 'Male';
  maritalStatus: string = 'Married';
  home: string = 'NA';
  road: string = 'NA';
  block: string = 'NA';
  city: string = 'Manama';
  country: string = 'Bahrain';
  accountNumber: string = '';
  dateOfBirth: string = '2020-11-29T00:00';
  licenseIssueDate: string = '2010-01-01T00:00';
  usertype: string = 'Organization';
}
export class SendOtpModel {
  mobileNumber: string = '';
}
export class GetOTPModel {
  firstNumber: string = '';
  SecondNumber: string = '';
  thirdNumber: string = '';
  forthNumber: string = '';
  pinId: string = '';
}
export class OTPModel {
  pinId: string = '';
  pin: number = 0;
}
