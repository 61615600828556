<ng-container *ngIf="isActive">
  <ng-container *ngIf="activeDirection === 'asc'">
    <div class="card-body">
      <img src="https://svgsilh.com/svg/147174.svg" width="15px" />
    </div>
    <!-- <span [inlineSVG]="'/src/assets/img/Up-2.svg'"
    [cacheSVG]="true"
      class="svg-icon svg-icon-sm svg-icon-primary ml-1">
    </span> -->
  </ng-container>
  <ng-container *ngIf="activeDirection === 'desc'">
    <div class="card-body">
      <img src="  https://svgsilh.com/svg/40168.svg" width="15px" />
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isActive">
  <div class="card-body">
    <img src="  https://svgsilh.com/svg/40168.svg" width="15px" />
  </div>
  <!-- <span [inlineSVG]="'/src/assets/img/Down-2.svg'"
      [cacheSVG]="true"
    class="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
  </span> -->
</ng-container>
